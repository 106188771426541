//React
import { useEffect, useState } from "react";

//Hooks
import { useApi } from "../hooks/useApi";
import { useMapInfo } from "../hooks/useMapInfo";
import { useMapUtil } from "../hooks/useMapUtil";

//Leaflet & geo
import { GeoJSON, Marker } from 'react-leaflet';
import * as d3 from "d3";

function MapCadastreLayerComponent() {
    /**
     * Hooks
     */
    const { mapInfo, addStoreParcelle, removeStoreParcelle } = useMapInfo();
    const { createLoader, deleteLoader } = useMapUtil();
    const { getCadastreGeoJSON } = useApi();

    /**
     * States
     */
    const [cadastres, setCadastres] = useState(null);
    const [selectedCadastre, setSelectedCadastre] = useState(null); //Pour afficher les infos du cadastre sélectionné
    const [layerKey, setLayerKey] = useState(0); //Pour forcer le rechargement du layer cadastre (voir useEffect)

    /**
     * useEffects
     */
    useEffect(() => {
        if(mapInfo.address?.citycode) {
            createLoader('cadastre', 'Chargement du cadastre...');
            getCadastreGeoJSON(mapInfo.address.citycode, mapInfo.address.coordinates.latitude, mapInfo.address.coordinates.longitude)
                .then((data) => {
                    setCadastres(data);
                    deleteLoader('cadastre');
                    //On ajoute le cadastre le plus proche de l'adresse dans les selectedCadastre
                    addCoordinatesToSelectedCadastre(data, mapInfo.address.coordinates.latitude, mapInfo.address.coordinates.longitude);
                })
                .catch((error) => {
                    deleteLoader('cadastre');
                    console.log(error);
                });
        }
    }, [mapInfo.address]);

    useEffect(() => {
        setLayerKey(layerKey + 1);
    }, [cadastres, selectedCadastre]);

    useEffect(() => {
        if(mapInfo.parcelles) {
            setSelectedCadastre({
                type: "FeatureCollection",
                features: mapInfo.parcelles.map((parcelle) => {
                    return parcelle.feature;
                })
            });
        }
    }, [mapInfo.parcelles]);

    //Ajout du cadastre le plus proche de coordonnées dans les selectedCadastre
    const addCoordinatesToSelectedCadastre = (cadastreData, latitude, longitude) => {
        //On parcourt les cadastres pour trouver le plus proche
        let closestCadastre = null;
        let cadastreFound = false;
        let closestDistance = null;
        
        cadastreData.features.forEach((cadastre) => {
            if(cadastreFound) return;
            
            //On commence par regarde si les coordonnées sont contenues dans le polygon de la parcelle
            let isInside = d3.polygonContains(cadastre.geometry.coordinates[0], [longitude, latitude]);
            
            if(isInside) {
                closestCadastre = cadastre;
                cadastreFound = true;
            }
            //Pour chaque parcelle, on calcule les coordonnées du point central
            let coordinates = cadastre.geometry.coordinates[0];
            let x = 0;
            let y = 0;
            coordinates.forEach((coordinate) => {
                x += coordinate[0];
                y += coordinate[1];
            });
            x = x / coordinates.length;
            y = y / coordinates.length;
            //On calcule la distance entre le point central et les coordonnées
            let distance = Math.sqrt(Math.pow(x - longitude, 2) + Math.pow(y - latitude, 2));
            if(!closestDistance || distance < closestDistance) {
                closestCadastre = cadastre;
                closestDistance = distance;
            }
        });
        
        if(!closestCadastre) return;
        //On ajoute le cadastre dans les parcelles sélectionnées
        addStoreParcelle({
            id: closestCadastre.id,
            commune: closestCadastre.properties.commune,
            surface: closestCadastre.properties.contenance,
            prefixe: closestCadastre.properties.prefixe,
            section: closestCadastre.properties.section,
            numero: closestCadastre.properties.numero,
            coordinates: closestCadastre.geometry.coordinates[0],
            feature: closestCadastre
        });
    };

    if(!cadastres) return (
        <>
        </>
    );

    /**
     * Handlers
     */
    const handleClickOnCadastre = (e) => {
        //On regarde si la parcelle est déjà présente dans le store
        let isAlreadyPresent = false;
        mapInfo.parcelles.forEach((parcelle) => {
            if(parcelle.id == e.target.feature.id) isAlreadyPresent = true;
        });

        //Si elle n'est pas présente, on l'ajoute
        if(!isAlreadyPresent) {
            addStoreParcelle({
                    id: e.target.feature.id,
                    commune: e.target.feature.properties.commune,
                    surface: e.target.feature.properties.contenance,
                    prefixe: e.target.feature.properties.prefixe,
                    section: e.target.feature.properties.section,
                    numero: e.target.feature.properties.numero,
                    coordinates: e.target.feature.geometry.coordinates[0],
                    feature: e.target.feature
                });
        }
        else {
            //Sinon on la supprime
            removeStoreParcelle(e.target.feature.id);
        }
    }

    const onEachCadastre = (feature, layer) => {
        layer.on({
            click: handleClickOnCadastre
        });
    }
    
    return (
        <>
            <GeoJSON
                key={layerKey}
                data={cadastres}
                style={{color: 'white'}}
                onEachFeature={onEachCadastre}/>
            {selectedCadastre &&
                <GeoJSON
                    key={layerKey+'selected'}
                    data={selectedCadastre}
                    style={{color: 'blue'}}
                    onEachFeature={onEachCadastre}/>
            }
        </>
    );
}

export default MapCadastreLayerComponent;