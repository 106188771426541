import CardAddressComponent from "./CardAddressComponent";

function TopLeftContentComponent() {
  return (
    <div className="layout-topleft">
        <CardAddressComponent />
    </div>
  );
}

export default TopLeftContentComponent;