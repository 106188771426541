import { useDispatch, useSelector } from "react-redux";
import { selectMapInfo, setMapCoordinates, setMapAddress, addMapParcelle, setMapParcelles, setBrouillon } from "../store/mapInfoSlice";

export const useMapInfo = () => {
    const dispatch = useDispatch();
    const mapInfo = useSelector(selectMapInfo);

    const centerMap = (centerCoordinates, zoom = 19) => {
        dispatch(setMapCoordinates({...centerCoordinates, zoom: zoom}));
    }

    const setStoreAddress = (address) => {
        dispatch(setMapAddress(address));
        centerMap(address.coordinates);
    }

    const addStoreParcelle = (parcelle) => {
        dispatch(addMapParcelle(parcelle));
    }

    const removeStoreParcelle = (parcelleId) => {
        const newParcelles = mapInfo.parcelles.filter((parcelle) => parcelle.id !== parcelleId);
        dispatch(setMapParcelles(newParcelles));
    }

    const cleanStoreParcelle = () => {
        dispatch(setMapParcelles([]));
    }

    const setStoreBrouillon = (brouillon) => {
        dispatch(setBrouillon(brouillon));
    }

    return {
        mapInfo,
        centerMap,
        setStoreAddress,
        addStoreParcelle,
        removeStoreParcelle,
        cleanStoreParcelle,
        setStoreBrouillon
    }
}
