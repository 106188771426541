//Redux
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	loaders: [],
};

/**
 * The Map slice
 */
export const mapUtilSlice = createSlice({
	name: 'mapUtil',
	initialState,
	reducers: {
        setMapUtil: (state, action) => {
            return state = action.payload;
        },
        addLoader: (state, action) => {
            state.loaders.push(action.payload);
        },
        removeLoader: (state, action) => {
            state.loaders = state.loaders.filter(loader => loader.id !== action.payload);
        }
	}
});

export const { setMapUtil, addLoader, removeLoader } = mapUtilSlice.actions;
export const selectMapUtil = (state) => state.mapUtil;

export default mapUtilSlice.reducer;
