//React
import { useEffect, useRef, useState } from "react";

//Hooks
import { useMapInfo } from "../hooks/useMapInfo";
import { useApi } from "../hooks/useApi";
import { SubmitHandler, set, useForm } from "react-hook-form";

//Bootstrap
import { Button, Card, Row, Col, Modal, Spinner, FloatingLabel, Form, InputGroup } from "react-bootstrap";

//Models
import { Brouillon } from "../models/Brouillon";
import { UserRegister } from "../models/UserRegister";

//Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faHouseCircleCheck, faHouseCircleXmark, faUser } from "@fortawesome/free-solid-svg-icons";

//Components
import Swal from "sweetalert2";

function WelcomeComponent() {
    /**
     * Hooks
     */
    
    /**
     * States
     */
    const [showModal, setShowModal] = useState(false);

    /**
     * useEffects
     */
    useEffect(() => {
        setShowModal(true);
    }, []);

    /**
     * Handlers
     */
    

    return (
        <>
            <Modal show={showModal} onHide={() => setShowModal(false)} size="lg" centered>
                <Modal.Body>
                    <p className="fs-5">🔗 Bienvenue sur <span className="fw-bold text-primary">Liaisons Habitat</span>, le portail immobilier <span className="fw-bold text-primary">foncièr</span>ement connecté. 🔗</p>
                    <p>Le portail vous permet de vendre votre bien ou votre terrain efficacement à nos partenaires d'acteurs de la promotion, incluant promoteurs 🏢, marchands de biens 🏠, lotisseurs 🌳, bailleurs 🏘️, foncières 🏦, et investisseurs 💼.</p>
                    <p>
                        <u>Voici les étapes pour soumettre votre bien à la vente:</u><br />
                        <strong>1. Renseignement du bien :</strong> Commencez par entrer l'adresse du bien ou du terrain 📍 et sélectionnez le ou les parcelles du projet 🗺️.<br />
                        <strong>2. Soumission à l'éligibilité :</strong> Une fois les informations renseignées, soumettez votre bien pour évaluation. Cette action permet à nos équipes de recevoir votre proposition.<br />
                        <strong>3. Suivi de l'avancement et mise en ligne :</strong> Après soumission, vous obtiendrez un accès à un espace personnel où vous pourrez suivre l'évolution de la qualification de votre bien. Une fois le bien qualifié, nous procéderons à sa mise en ligne, vous permettant ainsi d'être visible auprès de nos partenaires 🔄.<br />
                        <strong>4. Recevez vos offres d'achat :</strong> À cette étape, vous commencerez à recevoir des offres d'achat ou des propositions de rencontre de la part des acteurs intéressés par votre bien 🛡️.
                    </p>
                    <p>
                        <u>A noter :</u><br />
                        <ul>
                            <li>
                                Votre bien ne sera pas exposé sur internet 🚫💻, garantissant une confidentialité totale. Seuls nos partenaires certifiés auront accès aux détails de votre proposition 🔒.
                            </li>
                            <li>
                                Seuls les propriétaires ou les intermédiaires disposant d'un mandat ou en cours de signature peuvent déposer un bien.
                            </li>
                        </ul>
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowModal(false)}>
                        Continuer
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default WelcomeComponent;