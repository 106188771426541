//Components
import CardParcellesComponent from "./CardParcellesComponent";

function BottomMiddleContentComponent() {
  return (
    <div style={{ position: 'absolute', bottom: '10px', left: '0px', width: '100%', zIndex: 10000 }} className="d-flex justify-content-center">
        <CardParcellesComponent />
    </div>
  );
}

export default BottomMiddleContentComponent;