//React
import { useEffect, useState } from "react";

//Hooks
import { useMapInfo } from "../hooks/useMapInfo";

//Bootstrap
import { Button, Card, Modal } from "react-bootstrap";

//Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuildingFlag, faRulerCombined } from "@fortawesome/free-solid-svg-icons";

//Components
import ButtonCreationBrouillonComponent from "./ButtonCreationBrouillonComponent";

function CardParcellesComponent() {
    /**
     * Hooks
     */
    const { mapInfo } = useMapInfo();
    
    /**
     * States
     */
    const [surfaceTotale, setSurfaceTotale] = useState(0);

    /**
     * useEffects
     */
    useEffect(() => {
        let surface = 0;
        mapInfo.parcelles.forEach((parcelle) => {
            surface += parcelle.surface;
        });

        setSurfaceTotale(surface);
    }, [mapInfo.parcelles]);

    /**
     * Handlers
     */

    if(!mapInfo.parcelles || mapInfo.parcelles.length == 0) return <></>;

    return (
        <>
            <Card className="card-parcelles">
                <Card.Body className="d-flex justify-content-between  align-items-start">
                    <div className="text-left" style={{ minWidth: '33%'}}>
                        <span><FontAwesomeIcon icon={faBuildingFlag} /> <strong><big>Ref. cadastrale</big></strong></span><br />
                        {[...mapInfo.parcelles].sort((a, b) => (a.prefixe+a.section+a.numero+'') < (b.prefixe+b.section+b.numero+'')?-1:1).map((parcelle, key) => (
                            (key>0?', ':'') + (Number(parcelle.prefixe)>0?parcelle.prefixe:'') + parcelle.section + parcelle.numero
                        ))}
                    </div>
                    <div className="text-left" style={{ minWidth: '33%'}}>
                        <span><FontAwesomeIcon icon={faRulerCombined} /> <strong><big>Surface</big></strong></span><br />
                        {surfaceTotale.toLocaleString('fr-FR', { maximumFractionDigits: 0 })} m²
                    </div>
                    <div>
                        <ButtonCreationBrouillonComponent />
                    </div>
                </Card.Body>
            </Card>
        </>
    );
}

export default CardParcellesComponent;