//Redux
import { createSlice } from "@reduxjs/toolkit";
import { COORDINATES_DEFAULT } from "../config/constants";

const initialState = {
	centerCoordinates: {
        latitude: COORDINATES_DEFAULT.latitude,
        longitude: COORDINATES_DEFAULT.longitude,
        zoom: COORDINATES_DEFAULT.zoom
    },
    address: {
        id: null,
        label: null,
        postcode: null,
        housenumber: null,
        street: null,
        city: null,
        citycode: null,
        score: null,
        coordinates: {
            latitude: null,
            longitude: null
        }
    },
    parcelles: [],
    brouillon: null
};

/**
 * The Map slice
 */
export const mapInfoSlice = createSlice({
	name: 'mapInfo',
	initialState,
	reducers: {
        setMapInfo: (state, action) => {
            return state = action.payload;
        },
        setMapCoordinates: (state, action) => {
            state.centerCoordinates = action.payload;
        },
        setMapAddress: (state, action) => {
            state.address = action.payload;
        },
        setBrouillon: (state, action) => {
            state.brouillon = action.payload;
        },
        addMapParcelle: (state, action) => {
            state.parcelles.push(action.payload);
        },
        setMapParcelles: (state, action) => {
            state.parcelles = action.payload;
        }
	}
});

export const { setMapInfo, setMapCoordinates, setMapAddress, addMapParcelle, setMapParcelles, setBrouillon } = mapInfoSlice.actions;
export const selectMapInfo = (state) => state.mapInfo;

export default mapInfoSlice.reducer;
