//React
import { useEffect } from "react";

//Hooks
import { useMapInfo } from "../hooks/useMapInfo";
import { useMap } from "react-leaflet";

function MapActionComponent() {
    const map = useMap();
    const { mapInfo } = useMapInfo();

    useEffect(() => {
        if(mapInfo.centerCoordinates) map.flyTo([mapInfo.centerCoordinates.latitude, mapInfo.centerCoordinates.longitude], mapInfo.centerCoordinates.zoom);
    }, [mapInfo.centerCoordinates]);
    
    return null;
}

export default MapActionComponent;