export const useDataGouv = () => {
    const apiAddress = (query, limit) => {
        return new Promise((resolve, reject) => {
            fetch('https://api-adresse.data.gouv.fr/search/?q=' + query + '&limit=' + limit)
                .then((response) => {
                    if(response.status >= 400) response.json().then((error) => reject(error));
                    else resolve(response.json());
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    return {
        apiAddress
    }
}
