//Models
import { Brouillon } from "../models/Brouillon";

export const useApi = () => {
    const getCadastreGeoJSON = (codeInsee, latitude = null, longitude = null) => {
        return new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_API_URL+'/tools/geo/cadastre/' + codeInsee + '/' + latitude + '/' + longitude)
                .then((response) => {
                    if(response.status >= 400) response.json().then((error) => reject(error));
                    else resolve(response.json());
                });
        });
    }

    const getReferentielValues = (type) => {
        return new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_API_URL+'/tools/referentiel/' + type)
                .then((response) => {
                    if(response.status >= 400) response.json().then((error) => reject(error));
                    else resolve(response.json());
                });
        });
    }

    const checkEligibilite = (brouillon: Brouillon) => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(brouillon)
            };
            
            fetch(process.env.REACT_APP_API_URL+'/tools/brouillon/check', requestOptions)
                .then((response) => {
                    if(response.status >= 400) response.json().then((error) => reject(error));
                    else resolve(response.json());
                });
        });
    }

    const createBrouillon = (datas) => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(datas)
            };
            
            fetch(process.env.REACT_APP_API_URL+'/tools/brouillon/create', requestOptions)
                .then((response) => {
                    if(response.status >= 400) response.json().then((error) => reject(error));
                    else resolve(response.json());
                });
        });
    }

    const transmissionPartenaires = (datas: any) => {
        return new Promise((resolve, reject) => {
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(datas)
            };
            
            fetch(process.env.REACT_APP_API_URL+'/tools/contact/transmission/partenaires', requestOptions)
                .then((response) => {
                    if(response.status >= 400) response.json().then((error) => reject(error));
                    else resolve(response.json());
                });
        });
    }

    return {
        getCadastreGeoJSON,
        getReferentielValues,
        checkEligibilite,
        createBrouillon,
        transmissionPartenaires
    }
}
