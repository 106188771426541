//React
import { useEffect, useRef, useState } from "react";

//Hooks
import { useMapInfo } from "../hooks/useMapInfo";
import { useApi } from "../hooks/useApi";
import { SubmitHandler, set, useForm } from "react-hook-form";

//Bootstrap
import { Button, Card, Row, Col, Modal, Spinner, FloatingLabel, Form, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";

//Models
import { Brouillon } from "../models/Brouillon";
import { UserRegister } from "../models/UserRegister";

//Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faHouseChimneyUser, faHouseCircleCheck, faHouseCircleXmark, faInfoCircle, faUser } from "@fortawesome/free-solid-svg-icons";

//Components
import Swal from "sweetalert2";

interface IFormInputEligibilite {
    typeBien: number;
    prix: number;
}

interface IFormInputDepot {
    nom: string;
    prenom: string;
    email: string;
    typeCompte: string;
    telephone: string;
    activite: number;
    reseauAgence: number;
    societe: string;
    password: string;
    passwordConfirm: string;
}

interface IFormInputTransmission {
    nom: string;
    prenom: string;
    email: string;
    telephone: string;
    souhaitContact: number;
}

function ButtonCreationBrouillonComponent() {
    /**
     * Hooks
     */
    const { mapInfo, setStoreBrouillon, cleanStoreParcelle } = useMapInfo();
    const { getReferentielValues, checkEligibilite, createBrouillon, transmissionPartenaires } = useApi();
    const { register: registerEligibilite, handleSubmit: handleSubmitEligibilite, watch: watchEligibilite, formState: { errors: errorsEligibilite } } = useForm();
    const { register: registerDepot, getValues: getValuesDepot, handleSubmit: handleSubmitDepot, watch: watchDepot, formState: { errors: errorsDepot } } = useForm();
    const { register: registerTransmission, getValues: getValuesTransmission, handleSubmit: handleSubmitTransmission, watch: watchTransmission, formState: { errors: errorsTransmission } } = useForm();
    
    /**
     * States
     */
    const [showModalEligibilite, setShowModalEligibilite] = useState(false);
    const [showModalResultat, setShowModalResultat] = useState(false);
    const [loadingForm, setLoadingForm] = useState(false);
    const [loadingResultat, setLoadingResultat] = useState(false);
    const [loadingDepot, setLoadingDepot] = useState(false);
    const [loadingTransmission, setLoadingTransmission] = useState(false);
    const [typesBien, setTypesBien] = useState([]);
    const [typesTier, setTypesTier] = useState([]);
    const [reseauxAgence, setReseauxAgence] = useState([]);
    const [typeCompte, setTypeCompte] = useState('');
    const [activite, setActivite] = useState(0);
    const [bienEligible, setBienEligible] = useState(false);

    /**
     * useEffects
     */
    useEffect(() => {
        setLoadingForm(true);
        //Chargement des types de biens
        getReferentielValues('TYPE_BROUILLON_TYPE_BIEN')
            .then((values: any[]) => {
                setTypesBien(values);
                //Chargement des types de tiers
                getReferentielValues('TYPE_TYPE_TIER')
                    .then((typesTierValues: any[]) => {
                        setTypesTier(typesTierValues);
                        //Chargement des réseaux d'agence
                        getReferentielValues('TYPE_RESEAU_AGENCE')
                            .then((reseauAgenceValues: any[]) => {
                                setReseauxAgence(reseauAgenceValues);
                                setLoadingForm(false);
                            })
                            .catch((error) => {
                                console.log(error);
                                setLoadingForm(false);
                            });
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error);
                setLoadingForm(false);
            });
    }, []);

    /**
     * Handlers
     */
    const onSubmitEligibilite: SubmitHandler<IFormInputEligibilite> = (data) => {
        //Calcul de la surface du bien
        let surface = 0;
        mapInfo.parcelles.forEach((parcelle) => {
            surface += parcelle.surface;
        });
        
        //On commence par créer le brouillon
        const brouillon: Brouillon = {
            typesDeBien: [data.typeBien],
            prixNetVendeur: data.prix,
            adressePostale: mapInfo.address.housenumber + ' ' + mapInfo.address.street,
            codePostal: mapInfo.address.postcode,
            commune: mapInfo.address.city,
            surface: surface,
            parcelles: mapInfo.parcelles.map((parcelle) => {
                return {
                    prefixe: parcelle.prefixe,
                    section: parcelle.section,
                    numero: parcelle.numero,
                    surface: parcelle.surface,
                    adressePostale: mapInfo.address.housenumber + ' ' + mapInfo.address.street,
                    codePostal: mapInfo.address.postcode,
                    commune: mapInfo.address.city,
                };
            })
        };

        //On enregistre le brouillon dans le store
        setStoreBrouillon(brouillon);

        //On valide ensuite l'elligibilité du bien
        setShowModalEligibilite(false);
        setLoadingResultat(true);
        setShowModalResultat(true);
        setBienEligible(false);
        checkEligibilite(brouillon)
            .then((response: any) => {
                setBienEligible(response.eligible);
                
                setLoadingResultat(false);
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Oups!',
                    html: 'Une erreur est survenue lors du test d\'éligibilité.<br />'+error.detail,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
                
                setLoadingResultat(false);
            });
    };

    const handleClickTypeCompte = (event) => {
        setTypeCompte(event.target.value);
    }

    const handleChangeActivite = (event) => {
        setActivite(event.target.value);
    }

    const onSubmitDepot: SubmitHandler<IFormInputDepot> = (data) => {
        const user: UserRegister = {
            email: data.email,
            password: data.password,
            nom: data.nom,
            prenom: data.prenom,
            telephone: data.telephone,
            typeCompte: data.typeCompte,
            activite: typeCompte === 'agentImmo'?typesTier.find((typeTier) => typeTier.label === 'Agent immobilier').id:data.activite,
            reseauAgence: data.reseauAgence,
            societe: data.societe,
        };
        
        const datas = {
            brouillon: mapInfo.brouillon,
            user: user,
        };

        setLoadingDepot(true);
        createBrouillon(datas)
            .then((response: any) => {
                setShowModalResultat(false);
                setLoadingDepot(false);
                //On nettoie le store
                setStoreBrouillon(null);
                cleanStoreParcelle();
                Swal.fire({
                    title: 'Merci!',
                    html: 'Votre bien a bien été déposé sur notre plateforme.<br />Vous allez recevoir un email de confirmation afin de pouvoir vous connecter à notre plateforme.',
                    icon: 'success',
                    confirmButtonText: 'OK'
                });
            })
            .catch((error: any) => {
                Swal.fire({
                    title: 'Oups!',
                    html: 'Une erreur est survenue lors du dépôt de votre bien.<br />'+error.detail,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
                
                setShowModalResultat(false);
                setLoadingDepot(false);
            });
    };

    const onSubmitTransmission: SubmitHandler<IFormInputTransmission> = (data) => {
        setLoadingTransmission(true);
        transmissionPartenaires({
            nom: data.nom,
            prenom: data.prenom,
            email: data.email,
            telephone: data.telephone,
            souhaitContact: data.souhaitContact,
            codePostal: mapInfo.address.postcode,
            commune: mapInfo.address.city,
            typeDeBien: mapInfo.brouillon.typesDeBien[0],
            prixNetVendeur: mapInfo.brouillon.prixNetVendeur,
            surface: mapInfo.brouillon.surface,
        })
            .then((response: any) => {
                setLoadingTransmission(false);
                setShowModalResultat(false);
                Swal.fire({
                    title: 'Merci!',
                    html: 'Nous allons transmettre vos coordonnées à nos partenaires.',
                    icon: 'success',
                    confirmButtonText: 'OK'
                });
            });
    };

    return (
        <>
            <Button variant="primary" onClick={() => setShowModalEligibilite(true)}>Soumettre</Button>
            <Modal show={showModalEligibilite} onHide={() => setShowModalEligibilite(false)} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Déposez votre bien</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loadingForm &&
                        <>
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Nous préparons la suite...</span>
                            </Spinner>
                        </>
                    }
                    {!loadingForm &&
                        <Form noValidate onSubmit={handleSubmitEligibilite(onSubmitEligibilite)}>
                            <Row>
                                <Col>
                                    Afin soumettre votre bien à nos clients, veuillez remplir les informations suivantes afin de tester l'éligibilité de votre bien à notre service.
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} className="mt-3">
                                    <FloatingLabel controlId="floatingSelect" label="Quel est le type de votre bien ?">
                                        <Form.Select {...registerEligibilite('typeBien', { required: true })} className={errorsEligibilite.prix ? "is-invalid" : ""}>
                                            <option></option>
                                            {typesBien.map((typeBien) => (
                                                <option value={typeBien.id}>{typeBien.label}</option>
                                            ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Veuillez sélectionner un type de bien.
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                                <Col md={6} className="mt-3">
                                    <InputGroup>
                                        <Form.Floating>
                                            <Form.Control type="number" id="prixNetVendeur" {...registerEligibilite('prix', { required: true, min: 0 })} className={errorsEligibilite.prix ? "is-invalid" : ""} />
                                            <Form.Control.Feedback type="invalid">
                                                Veuillez saisir un prix valide.
                                            </Form.Control.Feedback>
                                            <label htmlFor="floatingPrix">
                                                Quel est le prix souhaité minimum ?&nbsp;
                                                <OverlayTrigger placement="top" overlay={<Tooltip>Ce montant permet à Liaisons Habitat la réalisation d'une estimation de votre bien et ne représente pas un engagement de votre part</Tooltip>}>
                                                    <FontAwesomeIcon className="pe-auto" icon={faInfoCircle} />
                                                </OverlayTrigger>
                                            </label>
                                        </Form.Floating>
                                    </InputGroup>
                                </Col>  
                            </Row>
                        </Form>
                    }
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModalEligibilite(false)}>
                        Annuler
                    </Button>
                    <Button variant="primary" onClick={handleSubmitEligibilite(onSubmitEligibilite)}>
                        Tester l'éligibilité
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showModalResultat} onHide={() => setShowModalResultat(false)} size="lg" centered>
                <Modal.Body>
                    {loadingResultat &&
                        <Row className="mt-3">
                            <Col className="text-center">
                                <Spinner animation="border" role="status" /><br />
                                <br />
                                <span>Nous étudions votre demande...</span>
                            </Col>
                        </Row>
                    }
                    {!loadingResultat && !bienEligible &&
                        <Form noValidate onSubmit={handleSubmitTransmission(onSubmitTransmission)}>
                            <Row>
                                <Col className="text-center">
                                    <FontAwesomeIcon icon={faHouseCircleXmark} size="3x" className="text-danger mb-3" /><br />
                                    <span className="text-danger fw-bold fs-5">Votre bien n'est pas éligible au portail</span><br />
                                    <span className="fs-6">
                                        Cependant, nous vous offrons la possibilité d'être mis en relation avec nos agences partenaires locales pour obtenir une estimation ou bénéficier d'un accompagnement dans votre démarche de vente.<br />
                                        Si cette option vous intéresse, veuillez compléter le formulaire associé pour que nous puissions transmettre vos coordonnées à nos partenaires.
                                    </span>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} className="mt-3">
                                    <InputGroup>
                                        <FloatingLabel controlId="floatingPrenom" label="Prénom">
                                            <Form.Control type="text" {...registerTransmission('prenom', { required: true })} className={errorsTransmission.prenom ? "is-invalid" : ""} />
                                            <Form.Control.Feedback type="invalid">
                                                Veuillez saisir un prénom.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </InputGroup>
                                </Col>
                                <Col md={6} className="mt-3">
                                    <InputGroup>
                                        <FloatingLabel controlId="floatingNom" label="Nom">
                                            <Form.Control type="text" {...registerTransmission('nom', { required: true })} className={errorsTransmission.nom ? "is-invalid" : ""} />
                                            <Form.Control.Feedback type="invalid">
                                                Veuillez saisir un nom.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} className="mt-3">
                                    <InputGroup>
                                        <FloatingLabel controlId="floatingEmail" label="Adresse email">
                                            <Form.Control type="text" {...registerTransmission('email', { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} className={errorsTransmission.email ? "is-invalid" : ""} />
                                            <Form.Control.Feedback type="invalid">
                                                Veuillez saisir une adresse email valide.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </InputGroup>
                                </Col>
                                <Col md={6} className="mt-3">
                                    <InputGroup>
                                        <FloatingLabel controlId="floatingEmail" label="Téléphone">
                                            <Form.Control type="text" {...registerTransmission('telephone', { required: true, pattern: /^[0-9]+$/ })} className={errorsTransmission.telephone ? "is-invalid" : ""} />
                                            <Form.Control.Feedback type="invalid">
                                                Veuillez saisir un numéro de téléphone valide.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} className="mt-3">
                                    <FloatingLabel controlId="floatingSelect" label="Je souhaite">
                                        <Form.Select {...registerTransmission('souhaitContact', { required: true })} className={errorsTransmission.souhaitContact ? "is-invalid" : ""}>
                                            <option></option>
                                            <option value="Estimation">une estimation</option>
                                            <option value="Vendre le bien">vendre mon bien</option>
                                            <option value="Bien déjà en vente">mon bien est déjà en vente</option>
                                            <option value="Projet en réflexion">projet en réflexion</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Veuillez sélectionner un souhait
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                        </Form>
                    }
                    {!loadingResultat && bienEligible &&
                        <Form noValidate onSubmit={handleSubmitDepot(onSubmitDepot)}>
                            <Row>
                                <Col className="text-center">
                                    <FontAwesomeIcon icon={faHouseCircleCheck} size="3x" className="text-success mb-3" /><br />
                                    <span className="text-success fw-bold fs-5">Votre bien est éligible à notre portail et pourrait capter l'intérêt de nos partenaires !</span><br />
                                    <span className="text-muted">
                                        <small>
                                            Nous vous invitons à compléter les champs ci-dessous pour accéder à votre espace personnel sur le portail.<br />
                                            Cet accès vous permettra de suivre l'évolution de votre dossier en temps réel et de faciliter la mise en relation avec nos équipes.
                                        </small>
                                    </span>
                                </Col>
                            </Row>
                            <Row className="mt-3 custom-options-checkable d-flex justify-content-center">
                                <Col>
                                    <input className="custom-option-item-check" type="radio" name="typeCompte" id="typeCompte1" value="proprietaire" {...registerDepot('typeCompte', { required: true })} onClick={handleClickTypeCompte} />
                                    <label className="custom-option-item text-center" htmlFor="typeCompte1">
                                        <FontAwesomeIcon icon={faUser} size="2x" className="text-primary mb-1" />
                                        <span className="custom-option-item-title d-block">Je suis le propriétaire</span>
                                    </label>
                                </Col>
                                <Col>
                                    <input className="custom-option-item-check" type="radio" name="typeCompte" id="typeCompte2" value="agentImmo" {...registerDepot('typeCompte', { required: true })} onClick={handleClickTypeCompte} />
                                    <label className="custom-option-item text-center" htmlFor="typeCompte2">
                                        <FontAwesomeIcon icon={faHouseChimneyUser} size="2x" className="text-primary mb-1" />
                                        <span className="custom-option-item-title d-block">Je suis agent immobilier</span>
                                    </label>
                                </Col>
                                <Col>
                                    <input className="custom-option-item-check" type="radio" name="typeCompte" id="typeCompte3" value="apporteur" {...registerDepot('typeCompte', { required: true })} onClick={handleClickTypeCompte} />
                                    <label className="custom-option-item text-center" htmlFor="typeCompte3">
                                        <FontAwesomeIcon icon={faAddressCard} size="2x" className="text-primary mb-1" />
                                        <span className="custom-option-item-title d-block">Je suis apporteur</span>
                                    </label>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} className="mt-3">
                                    <InputGroup>
                                        <FloatingLabel controlId="floatingPrenom" label="Prénom">
                                            <Form.Control type="text" {...registerDepot('prenom', { required: true })} className={errorsDepot.prenom ? "is-invalid" : ""} />
                                            <Form.Control.Feedback type="invalid">
                                                Veuillez saisir un prénom.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </InputGroup>
                                </Col>
                                <Col md={6} className="mt-3">
                                    <InputGroup>
                                        <FloatingLabel controlId="floatingNom" label="Nom">
                                            <Form.Control type="text" {...registerDepot('nom', { required: true })} className={errorsDepot.nom ? "is-invalid" : ""} />
                                            <Form.Control.Feedback type="invalid">
                                                Veuillez saisir un nom.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} className="mt-3">
                                    <InputGroup>
                                        <FloatingLabel controlId="floatingPrenom" label="Numéro de téléphone">
                                            <Form.Control type="text" {...registerDepot('telephone', { required: true, pattern: /^[0-9]+$/ })} className={errorsDepot.telephone ? "is-invalid" : ""} />
                                            <Form.Control.Feedback type="invalid">
                                                Veuillez saisir un numéro de téléphone
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </InputGroup>
                                </Col>
                            </Row>
                            {(typeCompte === 'apporteur' || typeCompte === 'agentImmo') &&
                                <Row>
                                    {typeCompte === 'apporteur' &&
                                        <Col md={6} className="mt-3">
                                            <FloatingLabel controlId="floatingSelect" label="Activité">
                                                <Form.Select {...registerDepot('activite', { required: true })} className={errorsDepot.activite ? "is-invalid" : ""} onChange={handleChangeActivite}>
                                                    <option></option>
                                                    {typesTier.filter((typeTier) => typeTier.registerVisibility && typeTier.label !== 'Agent immobilier').map((typeTier) => (
                                                        <option value={typeTier.id}>{typeTier.label}</option>
                                                    ))}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    Veuillez sélectionner une activité.
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                    }
                                    {typeCompte === 'agentImmo' &&
                                        <Col md={6} className="mt-3">
                                            <FloatingLabel controlId="floatingSelect" label="Réseau d'agence">
                                                <Form.Select {...registerDepot('reseauAgence', { required: true })} className={errorsDepot.reseauAgence ? "is-invalid" : ""}>
                                                    <option></option>
                                                    {reseauxAgence.map((reseauAgence) => (
                                                        <option value={reseauAgence.id}>{reseauAgence.label}</option>
                                                    ))}
                                                    <option value={0}>Autre</option>
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    Veuillez sélectionner un réseau d'agence.
                                                </Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Col>
                                    }
                                    {((typeCompte === 'agentImmo' && watchDepot('reseauAgence') == 0) || typeCompte === 'apporteur') &&
                                        <Col md={6} className="mt-3">
                                            <InputGroup>
                                                <FloatingLabel controlId="floatingNom" label="Société">
                                                    <Form.Control type="text" {...registerDepot('societe', { required: true })} className={errorsDepot.societe ? "is-invalid" : ""} />
                                                    <Form.Control.Feedback type="invalid" tooltip>
                                                        Veuillez saisir un nom de société.
                                                    </Form.Control.Feedback>
                                                </FloatingLabel>
                                            </InputGroup>
                                        </Col>
                                    }
                                </Row>   
                            }
                            <Row className="mt-3">
                                <Col>
                                    <hr />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <InputGroup>
                                        <FloatingLabel controlId="floatingEmail" label="Adresse email">
                                            <Form.Control type="text" {...registerDepot('email', { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} className={errorsDepot.email ? "is-invalid" : ""} />
                                            <Form.Control.Feedback type="invalid">
                                                Veuillez saisir une adresse email valide.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} className="mt-3">
                                    <InputGroup>
                                        <FloatingLabel controlId="floatingPassword1" label="Mot de passe">
                                            <Form.Control type="password" {...registerDepot('password', { required: true, pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/ })} className={errorsDepot.password ? "is-invalid" : ""} />
                                            <Form.Control.Feedback type="invalid">
                                                Veuillez saisir un mot de passe contenant au moins 1 majuscule, 1 chiffre et 8 caractères.
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </InputGroup>
                                </Col>
                                <Col md={6} className="mt-3">
                                    <InputGroup>
                                        <FloatingLabel controlId="floatingPassword2" label="Confirmation du mot de passe">
                                            <Form.Control type="password" {...registerDepot('passwordConfirm', { 
                                                required: true, 
                                                validate: (val: string) => val === watchDepot('password')
                                            })} className={errorsDepot.passwordConfirm ? "is-invalid" : ""} />
                                            <Form.Control.Feedback type="invalid">
                                                La confirmation du mot de passe doit être identique au mot de passe
                                            </Form.Control.Feedback>
                                        </FloatingLabel>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mt-3">
                                    <Form.Check type='checkbox'>
                                            <Form.Check.Input type='checkbox' id='acceptCGU' {...registerDepot('acceptCGU', { required: true, validate: (v) => v == 'accept' })} value='accept' required className={errorsDepot.acceptCGU ? "is-invalid" : ""} />
                                            <Form.Check.Label htmlFor='acceptCGU'>J'accepte les <a href='https://liaisons-habitat.fr/mentions-legales/' target='_blank'>conditions générales d'utilisation</a></Form.Check.Label>
                                    </Form.Check>
                                </Col>
                            </Row>
                        </Form>
                    }
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModalResultat(false)}>
                        Annuler
                    </Button>
                    {!loadingResultat && bienEligible &&
                        <Button variant="primary" onClick={handleSubmitDepot(onSubmitDepot)} disabled={loadingDepot}>
                            {loadingDepot && <Spinner animation="border" role="status" size="sm" />}
                            {!loadingDepot && <>Déposer mon bien et accéder à mon espace</>}
                        </Button>
                    }
                    {!loadingResultat && !bienEligible &&
                        <Button variant="primary" onClick={handleSubmitTransmission(onSubmitTransmission)} disabled={loadingTransmission}>
                            {loadingTransmission && <Spinner animation="border" role="status" size="sm" />}
                            {!loadingTransmission && <>Transmettre mes coordonnées</>}
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ButtonCreationBrouillonComponent;