//Components
import BottomMiddleContentComponent from './components/BottomMiddleComponent';
import MapComponent from './components/MapComponent';
import TopLeftContentComponent from './components/TopLeftContentComponent';
import WelcomeComponent from './components/WelcomeComponent';

//Styles
import './styles/App.scss';

function App() {
  return (
    <div className="App">
      <MapComponent />
      <TopLeftContentComponent />
      <BottomMiddleContentComponent />
      <WelcomeComponent />
    </div>
  );
}

export default App;
